import React from "react";
import { Box, Typography } from "@mui/material";
import { Loader } from "@swbc/swivel-components";

/**
 * Component for displaying a circular loader with text ("Loading..." by default).
 *
 * @component
 * @param {string} title Title of the loader.
 * @param {string} titleVariant Variant for Typography component (h1 by default).
 * @return {JSX.Element}
 */

export const LoadingIndicator = ({ title = "Loading...", titleVariant = "h1" }) => {
  return (
    <Box role="group">
      <Box sx={{ position: "relative", height: "5rem" }}>
        <Loader size="2rem" />
      </Box>
      <Typography variant={titleVariant} sx={{ textAlign: "center" }}>
        <output aria-live="assertive">{title}</output>
      </Typography>
    </Box>
  );
};
