import { creditCardBrand, states } from "@swbc/swivel-components";

export const constructSaveCardPayload = (data, token) => {
  const cardBrand = creditCardBrand(data.card_number);
  const [month, year] = data.card_expiry_date.split("/");
  const expMonth = parseInt(month, 10);
  const expYear = 2000 + parseInt(year, 10);
  const stateAbbreviation = states.titlecase.find(state => state.name === data?.billing_state)?.abbreviation;

  const payload = {
    card_details: {
      card_number: data.card_number,
      card_brand: cardBrand,
      expiration_month: expMonth,
      expiration_year: expYear,
      card_holder_name: data.card_holder_name,
      security_code: data.card_cvv,
      billing_address: data.billing_street,
      billing_city: data.billing_city,
      billing_state: stateAbbreviation,
      billing_zip: data.billing_zipcode
    },
    display_name: cardBrand,
    token
  };

  return payload;
};
