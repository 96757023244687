import { states } from "@swbc/swivel-components";

export const constructCardPayload = (paymentDetails, loan, token, config) => {

  const {
    amount,
    stateOfResidence,
    selectedPaymentMethod,
    pendingTransactions,
    convenienceFee,
    convenienceFeeSource
  } = paymentDetails;

  const stateAbbreviation = states.titlecase.find(state => state.name === selectedPaymentMethod?.billing_state)?.abbreviation;
  const stateOfResidenceAbbreviation = states.titlecase.find(state => state.name === stateOfResidence)?.abbreviation;

  if (selectedPaymentMethod?.id) {
    return {
      token,
      type: selectedPaymentMethod?.type,
      known_pending_transaction_numbers: pendingTransactions?.map((trans) => trans.TrackingNumber),
      loans: [{
        amount: Number(amount),
        id: loan?.id
      }],
      request_token: loan.idempotencyToken,
      state_of_residence: stateOfResidenceAbbreviation,
      fee: convenienceFee,
      fee_source: convenienceFeeSource,
      attribution: null,
      brandDetails: {
        brand_phone: config?.brand_phone
      },
      card_id: selectedPaymentMethod?.id
    };
  }

  const splitName = selectedPaymentMethod?.card_holder_name?.split(" ");
  const [firstName] = splitName;
  const lastName = splitName.slice(1).join(" ");



  return {
    token,
    type: selectedPaymentMethod?.type,
    known_pending_transaction_numbers: pendingTransactions?.map((trans) => trans.TrackingNumber),
    loans: [{
      amount: Number(amount),
      id: loan?.id
    }],
    card_details: {
      first_name: firstName,
      last_name: lastName,
      card_number: selectedPaymentMethod?.card_number,
      expiration_date: selectedPaymentMethod?.card_expiry_date,
      security_code: selectedPaymentMethod?.card_cvv,
      billing_address: {
        street: selectedPaymentMethod?.billing_street,
        city: selectedPaymentMethod?.billing_city,
        state: stateAbbreviation,
        zip_code: selectedPaymentMethod?.billing_zipcode
      }
    },
    request_token: loan.idempotencyToken,
    state_of_residence: stateOfResidenceAbbreviation,
    fee: convenienceFee,
    fee_source: convenienceFeeSource,
    attribution: null,
    brandDetails: {
      brand_phone: config?.brand_phone
    }
  };
};
