import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useTransactionContext } from "../../../context/index.js";
import { REGISTERED } from "../../../utils/constants/constants";
import { getAccountsAndCards } from "../api/getAccountsAndCards.js";

export const useAchAccountUpdate = ({ getAccounts }) => {
  const [achAccounts, setAchAccounts] = useState([]);
  useEffect(() => {
    const accounts = getAccounts?.response?.results;
    const transformedAchAccounts = accounts?.map((account) => {
      return {
        ...account,
        type: "ach",
        label: account?.banking_details?.name_of_bank
          ? account?.banking_details?.name_of_bank
          : "account",
        banking_details: {
          ...account.banking_details,
          type: account?.type
        }
      };
    });
    setAchAccounts(transformedAchAccounts);
  }, [getAccounts]);

  return achAccounts;
};

export const useAccountsAndCardsData = (setError) => {
  const authState = (state) => state.auth;
  const { authToken, flow, user } = useSelector(authState);

  return useQuery(
    ["getAccountsAndCards", user?.id],
    () => getAccountsAndCards({ token: authToken?.value }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: !!authToken?.value && flow === REGISTERED,
      onError: () => {
        setError({ message: "Unable to display your saved payment methods at this time", severity: "error" });
      }
    }
  );
};

export const useHandleDeleteSavedPaymentMethod = ({ error, setError, cardMutation, achMutation, setPaymentMethodId }) => {
  const authState = (state) => state.auth;
  const loansState = (state) => state.loans;

  const { authToken } = useSelector(authState);
  const { list } = useSelector(loansState);

  const { paymentDetails } = useTransactionContext();

  const deleteSavedPaymentMethod = (paymentMethod) => {
    if (error) {
      setError(null);
    }

    if (setPaymentMethodId) {
      setPaymentMethodId(paymentMethod?.id);
    }

    if (paymentDetails?.selectedPaymentMethod?.id === paymentMethod?.id) {
      setError({
        severity: "error",
        message:
        "This payment method is currently selected for a transaction and cannot be deleted. Please select a different payment method first."
      });

      if (setPaymentMethodId) {
        setPaymentMethodId("");
      }

      return;
    }

    const activeLoans = list.filter((loan) =>
      loan.autopay?.account_id.includes(paymentMethod?.id)
    );

    const linkedLoans = activeLoans.map(
      (loan) => `Ending in ${loan.number.slice(-4)}`
    );

    if (activeLoans.length) {
      setError({
        severity: "error",
        message: `Cannot delete, account is used for autopay loans: [${linkedLoans.join(", ")}]`
      });

      if (setPaymentMethodId) {
        setPaymentMethodId("");
      }
    } else {
      paymentMethod?.paymentType === "card"
        ? cardMutation.mutate({ id: paymentMethod?.id, token: authToken.value })
        : achMutation.mutate({ id: paymentMethod?.id, token: authToken.value });
    }
  };

  return deleteSavedPaymentMethod;
};
