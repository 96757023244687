import React from "react";
import { CreditCardForm, MaskTextField } from "@swbc/swivel-components";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert } from "../../../components";
import Title from "../../../components/Title";
import { useGoogleAnalytics } from "../../../services/analytics/hooks/useGoogleAnalytics";
import { constructSaveCardPayload } from "../../../services/payments/lib/construstSaveCardPayload";
import { postCard } from "../../../services/storedCards/api/postCard";

const SaveCardPage = () => {

  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;

  const { details } = useSelector(institutionState);
  const { authToken } = useSelector(authState);
  const { actions, categories, trackGtagEvent } = useGoogleAnalytics();

  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: postCard,
    onSuccess: () => {
      trackGtagEvent(actions.STORE_CARD, categories.ATTEMPT_SUCCESS);
      navigate("/profile");
    },
    onError: () => {
      trackGtagEvent(actions.STORE_CARD, categories.ATTEMPT_FAILURE);
    }
  });

  const handleAddCardSubmit = (form) => {
    const payload = constructSaveCardPayload(form.data, authToken?.value);
    mutation.mutate(payload);
  };

  const acceptedCardTypes = details?.accepted_card_types;
  const nativeBinList = details?.native_bin_list;
  const nativeBinListError = details?.native_bin_list_error_text;

  return (
    <>
      <Title title="Save Card Details" />
      <Alert message={mutation?.isError && mutation?.error?.response} severity={mutation?.error?.severity} />
      <CreditCardForm
        // eslint-disable-next-line no-unused-vars
        DateField={({ value, ...props }) => <MaskTextField mask="00/00" {...props} />}
        disableGutters={true}
        onSubmit={handleAddCardSubmit}
        isLoading={mutation.isLoading}
        options={{
          whitelistCreditCardBrands: acceptedCardTypes,
          blacklistCreditCardBins: nativeBinList,
          blacklistCreditCardBinsErrorMessage: nativeBinListError
        }}
      />
    </>
  );
};

export default SaveCardPage;
